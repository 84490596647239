<template>
  <div>
    <div class="search">
      <div class="title">商品列表</div>
      <hr />
      <el-form :inline="true" class="top" v-if="!packListData.foodId">
        <el-form-item label="标题:">
          <el-input v-model="params.title"></el-input>
        </el-form-item>
        <el-form-item label="所属分类:">
          <el-select v-model="fatherId" @change="getFartherCate(fatherId, 2)" placeholder="请选择">
            <el-option v-for="item in cateList" :key="item.cateId" :label="item.cateName"
              :value="item.cateId"></el-option>
          </el-select>
          <el-select v-if="fatherId" v-model="params.cateId" placeholder="请选择">
            <el-option v-for="item in cateListSun" :key="item.cateId" :label="item.cateName"
              :value="item.cateId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="上架" :value="1"></el-option>
            <el-option label="下架" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="万旅网显示:">
          <el-select clearable v-model="params.wlShow" placeholder="请选择">
            <el-option v-for="item in optionsShow" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="$router.push('/foodAdd')" v-if="status == 1">添加</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table v-if="!packListData.foodId" :data="tableData" border tooltip-effect="dark" style="width: 100%"
      align="center">
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center">
      </el-table-column> -->
      <el-table-column prop="foodId" label="ID" align="center">
      </el-table-column>

      <el-table-column label="排序" align="center" width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.dept" @change="changeSort(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center">
      </el-table-column>
      <el-table-column prop="cateName" label="所属分类" align="center">
      </el-table-column>
      <el-table-column prop="isShow" label="是否显示" align="center">
      </el-table-column>
      <el-table-column label="展示图" width="110" align="center">
        <template slot-scope="scope">
          <el-image style="width: 70px; height: 70px" :src="scope.row.showImg" :preview-src-list="[scope.row.showImg]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="strStatus" label="状态" width="80" align="center">
      </el-table-column>

      <el-table-column prop="isHot" label="是否热门" width="80" align="center">
      </el-table-column>

      <el-table-column prop="isTj" label="是否推荐" width="80" align="center">
      </el-table-column>
      <el-table-column prop="wlShow" label="万旅网显示" width="80" align="center">
      </el-table-column>

      <el-table-column prop="addTime" label="创建时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" :width="status == 1 ? '450' : '200'" align="center">
        <template slot-scope="scope">
          <el-button v-if="status == 2" type="primary" size="mini" @click="$emit('submit', scope.row)">确定</el-button>
          <div v-else>
            <el-button type="success" size="mini" v-if="scope.row.status == 2"
              @click="upData(scope.row, 1)">上架</el-button>
            <el-button type="warning" size="mini" v-if="scope.row.status == 1"
              @click="upData(scope.row, 2)">下架</el-button>
            <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="primary" size="mini" @click="
              $router.push('/foodPackageCate?foodId=' + scope.row.foodId)
              ">套餐分类</el-button>
              <el-button type="primary" size="mini" @click="
              $router.push('/foodPackList?foodId=' + scope.row.foodId)
              ">查看管理</el-button>
            <el-dropdown @command="command($event, scope.row)">
              <el-button style="margin-left: 10px" type="primary" size="mini">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">删除</el-dropdown-item>
                <el-dropdown-item command="2">一键复制</el-dropdown-item>
                <el-dropdown-item command="3">查看页面路径</el-dropdown-item>
                <el-dropdown-item command="4">生成二维码</el-dropdown-item>
                <el-dropdown-item command="5">查看日志</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">

      <div v-if="status == 1">
        <el-button type="primary" @click="upData('', 1)">批量上架</el-button>
        <el-button type="primary" @click="upData('', 2)">批量下架</el-button>
        <el-button type="primary" @click="remove('')">批量删除</el-button>
        <el-button type="primary" @click="recommend(1)">批量推荐</el-button>
        <el-button type="primary" @click="recommend(0)">批量取消推荐</el-button>
        <el-button type="primary" @click="setHot(1)">批量热门</el-button>
        <el-button type="primary" @click="setHot(0)">批量取消热门</el-button>
        <el-button type="primary" @click="onChangeAllStatusShow(1)"
          v-if="$store.state.powerList.indexOf('food:info:list:wlShow') !== -1">万旅网批量显示</el-button>
        <el-button type="primary" @click="onChangeAllStatusShow(0)"
          v-if="$store.state.powerList.indexOf('food:info:list:wlShow') !== -1">万旅网批量隐藏</el-button>
      </div>
      <div v-if="!packListData.foodId">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="params.currentPage" :page-sizes="[5, 10, 20]" :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看页面地址" :visible.sync="showAddress" width="35%">
      <div id="address">
        页面地址:<el-input v-model="ticketAddress" readonly id="wrapper"></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="copyinviteUrl">一键复制</el-button>
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input style="width: 300px" v-model.trim="QRcodeSize" placeholder="请输入生成的二维码大小"></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>



    <div v-if="packListData.foodId">
      <div class="searchss">
        <el-input v-model="packListData.packName" placeholder="请输入套餐名称" style="width: 150px;"></el-input>
        <el-button type="primary" style="margin-left: 20px" @click="getPackList()">查找</el-button>
        <el-button type="primary" @click="packListData.foodId = null">选择美食</el-button>
      </div>
      <el-table @selection-change="handleSelectionChange" :row-key="rowKey" size="mini" border :data="packDate"
        style="width: 100%">
        <el-table-column type="selection" width="55" :reserve-selection="true" align="center">
        </el-table-column>
        <el-table-column align="center" prop="packId" label="套餐ID" width="width">
        </el-table-column>
        <el-table-column align="center" prop="foodName" label="美食名称" width="width">
        </el-table-column>
        <el-table-column align="center" prop="packName" label="套餐名称" width="width">
        </el-table-column>
        <el-table-column align="center" prop="fee" label="价格" width="width">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="$emit('submit', row)">确定</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="ddffd">
        <div></div>
        <el-pagination @size-change="handleSizeChange6" @current-change="handleCurrentChange6"
          :current-page="packListData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="packListData.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="packPagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  foodList,
  foodCateAllById,
  foodListUpdata,
  foodListDel,
  foodListSort,
  createErFood,
  foodListCopy,
  modifyHot,
  modifyRecommendation,
  wanlvFoodShow,
  foodPackList
} from "../../api/food.js";
export default {
  props: {
    status: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      packDate: [],
      packPagination: [],
      codeVisible: false,
      QRcodeSize: "",
      foodId: "",
      imgUrl: "",

      ticketAddress: "",
      showAddress: false,
      dialogVisible: false,
      total: 0,
      fatherId: "",
      params: {
        currentPage: 1,
        pageSize: 5,
        title: "",
        cateId: "",
        status: 0,
        wlShow: -1,
      },
      packListData: {
        foodId: null,
        currentPage: 1,
        pageSize: 5,
        packName: '',
        status: 1
      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      optionsShow: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      ids: "",
      cateList: [],

      cateListSun: [],
      addRules: {
        tableData: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.getFartherCate();
    this.getList();
  },
  methods: {
    // onTaocanFood(id) {
    //   this.packListData.foodId = id;
    //   this.getPackList();
    // },
    async getPackList() {
      const { data } = await foodPackList(this.packListData);
      console.log(data, "13213adaw");
      this.packDate = data.list;
      this.packPagination = data.pagination;
    },
    handleSizeChange6(num) {
      this.packListData.pageSize = num;
      this.getPackList();
    },
    handleCurrentChange6(num) {
      this.packListData.currentPage = num;
      this.getPackList();
    },
    onChangeAllStatusShow(status) {
      if (!this.ids) {
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中美食的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids)
          const { data } = await wanlvFoodShow({
            foodId: this.ids,
            wlShow: status,
          });
          if (data.code === 0) {
            this.getList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {

        });

    },
    onShowAddress(id) {
      this.ticketAddress =
        "fineFood/pages/foodDetails/foodDetails?foodId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },
    rowKey(row) {
      return row.foodId;
    },
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.foodId).join(",");
      this.$emit("change", val);
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await foodList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    remove(row) {
      if (!row) {
        if (!this.ids) {
          this.$message.warning("请选择要操作的商品");
          return;
        }
      }
      this.$confirm(`此操作将删除商品, 是否继续?, 提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          foodListDel({
            foodIds: row ? row.foodId : this.ids,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message.success("操作成功");

              this.getList();
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        })
        .catch(() => { });
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    upData(row = "", status) {
      let str = "";
      if (!row) {
        if (!this.ids) {
          this.$message.warning("请选择要操作的商品");
          return;
        }
      }
      switch (status) {
        case 1:
          str = "上架";
          break;
        case 2:
          str = "下架";
          break;
      }

      this.$confirm(`此操作将${str}该商品, 是否继续?, 提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          foodListUpdata({
            status,
            foodIds: row ? row.foodId : this.ids,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message.success("操作成功");

              this.getList();
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        })
        .catch(() => { });
    },
    // 批量推荐
    async recommend(type) {
      if (!this.ids) {
        this.$message.warning("请选择要操作的商品");
        return;
      }
      const { data } = await modifyRecommendation({
        isTj: type,
        ids: this.ids,
      });
      if (data.code == 0) {
        this.getList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    // 批量热门
    async setHot(type) {
      if (!this.ids) {
        this.$message.warning("请选择要操作的商品");
        return;
      }
      const { data } = await modifyHot({ isHot: type, ids: this.ids });
      if (data.code == 0) {
        this.getList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    edit(row) {
      this.$router.push({
        path: "/foodAdd",
        query: {
          foodId: row.foodId,
        },
      });
    },
    changeSort(row) {
      foodListSort({
        dept: row.dept,
        foodId: row.foodId,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("设置成功");
          this.getList();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    getFartherCate(fatherId = 0, type = 1) {
      foodCateAllById({ fatherId }).then((res) => {
        if (res.data.code == 0) {
          if (type == 2) {
            this.cateListSun = res.data.data;
          } else {
            this.cateList = res.data.data;
          }

          //   this.fatherId = res.data.data[0].cateId;
        }
      });
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    command(e, row) {
      console.log(e, row);
      if (e == 1) {
        this.remove(row);
      } else if (e == 2) {
        this.copy(row.foodId);
      } else if (e == 3) {
        this.onShowAddress(row.foodId);
      } else if (e == 4) {
        this.creatCode(row.foodId);
      } else if (e == 5) {
        this.$router.push("/foodLog/" + row.foodId);
      }
    },
    creatCode(id) {
      this.foodId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createErFood({
          foodId: this.foodId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    copy(foodId) {
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await foodListCopy({
            foodId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}

.top {
  margin-top: 20px;
}

.block {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.searchss {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}
.ddffd{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20rpx;
}
</style>
